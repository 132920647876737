import React from 'react'

import Root from '../components/Root'

const Terms = () => (
    <Root header="basic">
        <div className="max-w-6xl -mt-20 mx-auto px-8 md:px-12 lg:px-20 py-8 relative font-light text-black text-center z-10">
            <div className="bg-white p-8 rounded-lg">
                <h1 className="mb-8 font-display font-bold text-xl md:text-2xl lg:text-3xl uppercase">
                    Terms and Conditions
                </h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem amet temporibus deserunt reiciendis nemo impedit
                    perferendis animi quidem hic quo quam alias officiis illum
                    dolorum assumenda, voluptas, beatae harum repudiandae.
                </p>
                <p className="mt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem amet temporibus deserunt reiciendis nemo impedit
                    perferendis animi quidem hic quo quam alias officiis illum
                    dolorum assumenda, voluptas, beatae harum repudiandae.
                </p>
                <p className="mt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem amet temporibus deserunt reiciendis nemo impedit
                    perferendis animi quidem hic quo quam alias officiis illum
                    dolorum assumenda, voluptas, beatae harum repudiandae.
                </p>
                <p className="mt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem amet temporibus deserunt reiciendis nemo impedit
                    perferendis animi quidem hic quo quam alias officiis illum
                    dolorum assumenda, voluptas, beatae harum repudiandae.
                </p>
                <p className="mt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Voluptatem amet temporibus deserunt reiciendis nemo impedit
                    perferendis animi quidem hic quo quam alias officiis illum
                    dolorum assumenda, voluptas, beatae harum repudiandae.
                </p>
            </div>
        </div>
    </Root>
)

export default Terms
